import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { TemplatesApiService } from '@app/core/services/templates-api.service';
import { Template, Object1 } from '@app/core/models/template.model';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SnackBarComponent } from '@app/shared/components';
import { FundusStudiesFacadeService } from '@app/diagnosis/services';
import { firstValueFrom } from 'rxjs';
import {
  ConfirmDialogComponent,
  DialogData as ConfirmDialogData,
} from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { saveAs } from 'file-saver';

interface DiagnosisValue {
  assessment?: string;
  code: string;
  classification: string;
  _code?: string;
  _classification?: string;
  new?: boolean;
}

type ParsedCsv = {
  formValues: { [key: string]: string | boolean };
  diagnosisValues: { [key: string]: DiagnosisValue[] };
}

import {
  scheieH,
  scheieS,
  keithWagner,
  wongMitchell,
  modifiedDavis,
  newFukuda,
  overallAssessment,
  scott,
  findings,
} from '@app/diagnosis/constants';
@Component({
  selector: 'app-template-setting',
  templateUrl: './template-setting.component.html',
  styleUrls: ['./template-setting.component.scss'],
})
export class TemplateSettingComponent implements OnInit, OnChanges {
  @ViewChild('importEl') importEl!: ElementRef;

  @Input() set template(template: Template | undefined) {
    this._template = template;
    const initFormValues: { [key: string]: any } = {
      name: template?.name || '',
      scheie: template?.scheieH.required || false,
      keithWagner: template?.keithWagner.required || false,
      wongMitchell: template?.wongMitchell.required || false,
      modifiedDavis: template?.modifiedDavis.required || false,
      newFukuda: template?.newFukuda.required || false,
      scott: template?.scott.required || false,
      findingsText: template?.findings.required || false,
      overallAssessment: template?.overallAssessment.required || false,
      individualAssessment: template?.individualAssessment?.required || false,
      isDefaultTemplate: template?.isDefaultTemplate || false,
    };

    this.initFormValues = initFormValues;

    this.form.reset();
    for (let field in initFormValues) {
      this.form.controls[field].setValue(initFormValues[field]);
    }
  }

  @Input() templateList: { id: number; name: string; isDefault: boolean }[] =
    [];
  @Input() userRole?: string;
  @Output() templateUpdated = new EventEmitter<Template>();
  @Output() templateAdded = new EventEmitter<Template>();
  @Output() templateChanged = new EventEmitter<boolean>();
  @Output() templateDeleted = new EventEmitter<Template>();
  @Output() editCanceled = new EventEmitter();
  _template?: Template;
  initFormValues = {};
  form!: UntypedFormGroup;

  defaultClassifications = [
    {
      field: 'scheieS',
      name: 'Scheie (S)',
      defaultValues: scheieS,
      canAdd: false,
    },
    {
      field: 'scheieH',
      name: 'Scheie (H)',
      defaultValues: scheieH,
      canAdd: false,
    },
    {
      field: 'keithWagner',
      name: 'Keith-Wagner',
      defaultValues: keithWagner,
      canAdd: false,
    },
    {
      field: 'wongMitchell',
      name: 'Wong-Mitchell',
      defaultValues: wongMitchell,
      canAdd: true,
    },
    {
      field: 'modifiedDavis',
      name: '改変Davis',
      defaultValues: modifiedDavis,
      canAdd: true,
    },
    {
      field: 'newFukuda',
      name: '新福田',
      defaultValues: newFukuda,
      canAdd: true,
    },
    { field: 'scott', name: 'Scott', defaultValues: scott, canAdd: true },
    {
      field: 'findings',
      name: 'その他所見',
      defaultValues: findings,
      canAdd: true,
    },
    {
      field: 'overallAssessment',
      name: '総合判定',
      defaultValues: overallAssessment,
      canAdd: true,
    },
    {
      field: 'individualAssessment',
      name: '左右判定',
      defaultValues: overallAssessment,
      canAdd: true,
    },
  ];

  selectedDiagnosisItem = 'scheieS';
  diagnosisValues: { [key: string]: DiagnosisValue[] } = {};
  diagnosisValueErrors: { [key: string]: 'isEmpty' | 'isDuplicate' } = {};
  changedDiagnosisValues = false;
  removedDiagnosisValues = false;

  get name() {
    return this.form.controls['name'] as UntypedFormControl;
  }

  get nameAlreadyExists() {
    if (this._template?.name === this.name.value) {
      return false;
    }
    return this.templateList.some((template) => template.name === this.name.value)
  }

  get currentSelectedDiagnosisItem(): string {
    if (this.showDiagnosisItem(this.selectedDiagnosisItem)) {
      return this.selectedDiagnosisItem;
    } else if (this.hasCheckedOne) {
      for (let diagnosisItem of this.defaultClassifications) {
        if (this.showDiagnosisItem(diagnosisItem.field)) {
          return diagnosisItem.field;
        }
      }
    }

    return '';
  }

  get isReadOnly(): boolean {
    return this.userRole !== 'Admin';
  }

  get hasCheckedOne(): boolean {
    return (
      this.form.value.scheie ||
      this.form.value.keithWagner ||
      this.form.value.wongMitchell ||
      this.form.value.modifiedDavis ||
      this.form.value.newFukuda ||
      this.form.value.scott ||
      this.form.value.findingsText ||
      this.form.value.overallAssessment ||
      this.form.value.individualAssessment
    );
  }

  get diagnosisItemsWithErrors(): string[] {
    let diagnosisItemsWithErrors: string[] = [];

    for (let field in this.diagnosisValues) {
      for (let diagnosisValue in this.diagnosisValueErrors) {
        if (diagnosisValue.includes(field)) {
          diagnosisItemsWithErrors.push(field);
        }
      }
    }

    return diagnosisItemsWithErrors;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private templateApiService: TemplatesApiService,
    @Inject(MAT_DIALOG_DATA) public data: { organizationId: string },
    private matSnackBar: MatSnackBar,
    private fundusStudiesFacadeService: FundusStudiesFacadeService,
    private dialog: MatDialog,
  ) {
    this.form = this.fb.group({
      name: [''],
      scheie: [''],
      keithWagner: [''],
      wongMitchell: [''],
      modifiedDavis: [''],
      newFukuda: [''],
      scott: [''],
      findingsText: [''],
      overallAssessment: [''],
      individualAssessment: [''],
      isDefaultTemplate: false,
    });

    this.form.valueChanges.subscribe((_) => {
      this.resetUncheckedDiagnosisItems();
      this.templateChanged.emit(this.hasChanges());
    });
  }

  ngOnInit() {
    this.resetDiagnosisValues();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['template']) {
      this.resetDiagnosisValues();
      this.templateChanged.emit(false);
    }
  }

  async resetDiagnosisValues() {
    this.diagnosisValues = {};
    this.diagnosisValueErrors = {};
    this.changedDiagnosisValues = false;
    this.removedDiagnosisValues = false;
    this.selectedDiagnosisItem = 'scheieS';

    this.defaultClassifications.forEach((diagnosisItem) => {
      this.diagnosisValues[diagnosisItem.field] = [];
      diagnosisItem.defaultValues.forEach((defaultValue, index) => {
        let valuesFromTemplate =
          this._template?.[diagnosisItem.field as keyof Template];

        if (valuesFromTemplate) {
          let fieldValues: DiagnosisValue[] = [];
          (valuesFromTemplate as Object1).options.forEach((value) => {
            fieldValues.push({
              classification: value.classification,
              code: value.code,
              assessment: value.assessment,
            });
          });
          this.diagnosisValues[diagnosisItem.field] = fieldValues;
        } else {
          this.diagnosisValues[diagnosisItem.field].push({
            code: (index + 1).toString(),
            classification: defaultValue,
          });
        }
      });
    });

    if (this.individualAssessmentOnly()) {
      this.diagnosisValues['overallAssessment'] = this.diagnosisValues['individualAssessment'];
    } else {
      this.diagnosisValues['individualAssessment'] = this.diagnosisValues['overallAssessment'];
    }
  }
  async saveTemplate() {
    if (this.individualAssessmentOnly()) {
      this.diagnosisValues['overallAssessment'] = this.diagnosisValues['individualAssessment'];
    } else {
      this.diagnosisValues['individualAssessment'] = this.diagnosisValues['overallAssessment'];
    }

    let object1Values: { [key: string]: Object1 } = {};
    Object.entries(this.diagnosisValues).forEach(([key, values]) => {
      object1Values[key] = { required: false, options: [] };
      if (key === 'scheieH' || key === 'scheieS') {
        object1Values[key].required = this.form.value.scheie;
      } else if (key === 'findings') {
        object1Values[key].required = this.form.value.findingsText;
      } else {
        object1Values[key].required = this.form.value[key];
      }

      object1Values[key].options = [];
      values.forEach((value, index) => {
        object1Values[key].options.push({
          id: index + 1,
          assessment: value.assessment ?? '',
          code: value.code.toString().trim(),
          classification: value.classification.trim(),
        });
      });
    });

    const template: Template = {
      organizationId: this.data.organizationId,
      name: this.form.value.name,
      isDefaultTemplate: this.form.value.isDefaultTemplate || false,
      scheieH: object1Values['scheieH'],
      scheieS: object1Values['scheieS'],
      keithWagner: object1Values['keithWagner'],
      wongMitchell: object1Values['wongMitchell'],
      modifiedDavis: object1Values['modifiedDavis'],
      newFukuda: object1Values['newFukuda'],
      scott: object1Values['scott'],
      findings: object1Values['findings'],
      overallAssessment: object1Values['overallAssessment'],
      individualAssessment: object1Values['individualAssessment'],
    };

    if (this._template?.id) template.id = this._template.id.toString();

    try {
      const savedTemplate = await this.templateApiService.upsertTemplate(
        template
      );
      let successMessage = '診断テンプレートを変更しました';
      this.initFormValues = Object.assign({}, this.form.value);

      if (template.id) {
        this.templateUpdated.emit(savedTemplate);
      } else {
        this.templateAdded.emit(savedTemplate);
        successMessage = '診断テンプレートを作成しました';
      }

      await this.fundusStudiesFacadeService.fetchFundusStudies();

      this.matSnackBar.openFromComponent(SnackBarComponent, {
        data: {
          success: true,
          message: successMessage,
        },
        duration: 3 * 1000,
      });
    } catch (e) {
      let errorMessage = '診断テンプレートの作成が失敗しました';
      if (this._template?.id) {
        errorMessage = '診断テンプレートの変更が失敗しました';
      }
      alert(errorMessage);
    }
  }

  hasChanges(): boolean {
    return (
      JSON.stringify(this.form.value) !== JSON.stringify(this.initFormValues) ||
      this.changedDiagnosisValues ||
      this.removedDiagnosisValues
    );
  }

  handleCancel() {
    this.editCanceled.emit();
  }
  userCanUpdate(): boolean {
    return this.userRole == 'Admin';
  }

  isSaveAllowed(): boolean {
    return (
      this.name.value &&
      !this.nameAlreadyExists &&
      this.hasCheckedOne &&
      this.hasChanges() &&
      this.userCanUpdate() &&
      Object.keys(this.diagnosisValueErrors).length == 0
    );
  }

  resetUncheckedDiagnosisItems() {
    let diagnosisValues = JSON.parse(JSON.stringify(this.diagnosisValues));
    let diagnosisValueErrors = JSON.parse(
      JSON.stringify(this.diagnosisValueErrors)
    );

    this.defaultClassifications.forEach((diagnosisItem) => {
      if (!this.showDiagnosisItem(diagnosisItem.field)) {
        diagnosisValues[diagnosisItem.field] = [];

        diagnosisItem.defaultValues.forEach((defaultValue, index) => {
          diagnosisValues[diagnosisItem.field].push({
            code: index + 1,
            classification: defaultValue,
          });
        });

        Object.keys(diagnosisValueErrors).forEach((errorKey) => {
          if (errorKey.includes(diagnosisItem.field)) {
            delete this.diagnosisValueErrors[errorKey];
          }
        });
      }
    });

    if (this.individualAssessmentOnly()) {
      diagnosisValues['overallAssessment'] = diagnosisValues['individualAssessment']
    } else {
      diagnosisValues['individualAssessment'] = diagnosisValues['overallAssessment'];
    }

    this.diagnosisValues = diagnosisValues;
  }

  showDiagnosisItem(diagnosisItem: string) {
    if (diagnosisItem == 'scheieH' || diagnosisItem == 'scheieS') {
      return this.form.value.scheie;
    } else if (diagnosisItem == 'findings') {
      return this.form.value.findingsText;
    } else if (diagnosisItem === 'individualAssessment') {
      return this.individualAssessmentOnly();
    } else {
      return this.form.value[diagnosisItem];
    }
  }

  changeDiagnosisValues(
    $event: Event,
    field: string,
    index: number,
    type: string
  ): void {
    let newVal = ($event.target as HTMLInputElement).value;
    if (type === 'code') {
      if (this.diagnosisValues[field][index]['_code'] === newVal) {
        delete this.diagnosisValues[field][index]['_code'];
      } else if (!this.diagnosisValues[field][index]['_code']) {
        this.diagnosisValues[field][index]['_code'] =
          this.diagnosisValues[field][index].code;
      }

      this.diagnosisValues[field][index]['code'] = newVal;
    } else if (type === 'classification') {
      if (this.diagnosisValues[field][index]['_classification'] === newVal) {
        delete this.diagnosisValues[field][index]['_classification'];
      } else if (!this.diagnosisValues[field][index]['_classification']) {
        this.diagnosisValues[field][index]['_classification'] =
          this.diagnosisValues[field][index].classification;
      }

      this.diagnosisValues[field][index]['classification'] = newVal;
    }

    this.checkDiagnosisValueErrors();
  }

  addDiagnosisValue(field: string, index: number): void {
    let diagnosisValues = JSON.parse(JSON.stringify(this.diagnosisValues));
    diagnosisValues[field].splice(index + 1, 0, {
      code: '',
      classification: '',
      new: true,
    });

    this.diagnosisValues = diagnosisValues;
    this.checkDiagnosisValueErrors();
  }

  removeDiagnosisValue(field: string, index: number): void {
    let diagnosisValues = JSON.parse(JSON.stringify(this.diagnosisValues));
    diagnosisValues[field].splice(index, 1);

    this.diagnosisValues = diagnosisValues;
    this.removedDiagnosisValues = true;
    this.checkDiagnosisValueErrors();
  }

  checkDiagnosisValueErrors(): void {
    this.diagnosisValueErrors = {};
    this.changedDiagnosisValues = false;

    Object.entries(this.diagnosisValues).forEach(([field, values]) => {
      values.forEach((value, index) => {
        if (!value.code) {
          this.diagnosisValueErrors[`${field}:${index}:code`] = 'isEmpty';
        } else if ('_code' in value) {
          const duplicate = values.find((checkValue, checkIndex) => {
            return checkValue.code === value.code && checkIndex !== index;
          });

          if (duplicate) {
            this.diagnosisValueErrors[`${field}:${index}:code`] = 'isDuplicate';
          }
          this.changedDiagnosisValues = true;
        }

        if (!value.classification) {
          this.diagnosisValueErrors[`${field}:${index}:classification`] =
            'isEmpty';
        } else if ('_classification' in value) {
          const duplicate = values.find((checkValue, checkIndex) => {
            return (
              checkValue.classification === value.classification &&
              checkIndex !== index
            );
          });

          if (duplicate) {
            this.diagnosisValueErrors[`${field}:${index}:classification`] =
              'isDuplicate';
          }
          this.changedDiagnosisValues = true;
        }

        if (value.new) {
          this.changedDiagnosisValues = true;
        }
      });
    });

    this.templateChanged.emit(this.hasChanges());
  }

  hasCategoryAsHeader(field: string) {
    return !['overallAssessment', 'individualAssessment', 'findings'].includes(field);
  }

  individualAssessmentOnly(): boolean {
    return this.form.value['individualAssessment'] && !this.form.value['overallAssessment']
  }

  userCanDelete(): boolean {
    return this.userRole === 'Admin';
  }

  isDeleteAllowed(): boolean {
    return (
      !!this._template?.id &&
      !this._template?.isDefaultTemplate &&
      !this._template?.isUsed &&
      this.userCanDelete()
    );
  }

  async deleteTemplate() {
    if (!this._template?.id) {
      return;
    }

    const data: ConfirmDialogData = {
      title: '診断テンプレートを削除しますか？',
      content: 'この操作は元に戻せません',
      actions: ['キャンセル', '削除'],
      color: 'warn',
    };
    const confirm =  await firstValueFrom(this.dialog.open(ConfirmDialogComponent, {
        autoFocus: false,
        data,
      }).afterClosed());
    if (!confirm) {
      return;
    }

    try {
      await this.templateApiService.deleteTemplate(this._template?.id)
      this.templateDeleted.emit(this._template);
      this.matSnackBar.openFromComponent(SnackBarComponent, {
        data: {
          success: true,
          message: '診断テンプレートを削除しました',
        },
        duration: 3 * 1000,
      });
    } catch (e) {
      alert('診断テンプレートの削除に失敗しました');
    }
  }

  async onExportCsv() {
    const csv = await this.generateCsv();
    const name = `${this.form.value.name}.csv`;

    const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
    const blob = new Blob([bom, csv], {type: 'text/csv'});

    saveAs(blob, name);
  }

  async generateCsv() {
    type Record = {
      key: string
      value: string
    };

    const records: Record[] = [
      {
        key: '[診断テンプレート名]',
        value: this.form.value.name,
      },
      {
        key: '[デフォルト]',
        value: this.form.value.isDefaultTemplate ? '1' : '0',
      },
    ];

    records.push({
      key: '[Scheie]',
      value: this.form.value.scheie ? '1' : '0',
    });
    records.push({
      key: '[Scheie(S)]',
      value: '',
    });
    for (const dv of this.diagnosisValues['scheieS']) {
      records.push({
        key: dv.classification,
        value: dv.code,
      });
    }
    records.push({
      key: '[Scheie(H)]',
      value: '',
    });
    for (const dv of this.diagnosisValues['scheieH']) {
      records.push({
        key: dv.classification,
        value: dv.code,
      });
    }

    records.push({
      key: '[Keith-Wagner]',
      value: this.form.value.keithWagner ? '1' : '0',
    });
    for (const dv of this.diagnosisValues['keithWagner']) {
      records.push({
        key: dv.classification,
        value: dv.code,
      });
    }

    records.push({
      key: '[Wong-Mitchell]',
      value: this.form.value.wongMitchell ? '1' : '0',
    });
    for (const dv of this.diagnosisValues['wongMitchell']) {
      records.push({
        key: dv.classification,
        value: dv.code,
      });
    }

    records.push({
      key: '[改変Davis]',
      value: this.form.value.modifiedDavis ? '1' : '0',
    });
    for (const dv of this.diagnosisValues['modifiedDavis']) {
      records.push({
        key: dv.classification,
        value: dv.code,
      });
    }

    records.push({
      key: '[新福田]',
      value: this.form.value.newFukuda ? '1' : '0',
    });
    for (const dv of this.diagnosisValues['newFukuda']) {
      records.push({
        key: dv.classification,
        value: dv.code,
      });
    }

    records.push({
      key: '[Scott]',
      value: this.form.value.scott ? '1' : '0',
    });
    for (const dv of this.diagnosisValues['scott']) {
      records.push({
        key: dv.classification,
        value: dv.code,
      });
    }

    records.push({
      key: '[その他所見]',
      value: this.form.value.findingsText ? '1' : '0',
    });
    for (const dv of this.diagnosisValues['findings']) {
      records.push({
        key: dv.classification,
        value: dv.code,
      });
    }

    records.push({
      key: '[総合判定]',
      value: this.form.value.overallAssessment ? '1' : '0',
    });
    for (const dv of this.diagnosisValues['overallAssessment']) {
      records.push({
        key: dv.classification,
        value: dv.code,
      });
    }

    records.push({
      key: '[左右判定]',
      value: this.form.value.individualAssessment ? '1' : '0',
    });

    return [
      records.map(r => r.key).join(','),
      records.map(r => r.value).join(','),
    ].join('\r\n');
  }

  async onImportCsv(event: Event) {
    const target = event.target as HTMLInputElement;
    const file = target.files?.item(0);
    this.importEl.nativeElement.value = '';
    if (!file) {
      return;
    }

    const text = await this.readFile(file);

    const { parsedCsv, errors: parseErrors } = this.parseCsv(text);
    if ((parseErrors?.length ?? 0) > 0) {
      const message = parseErrors?.join('\n') ?? '';
      const data: ConfirmDialogData = {
        title: 'CSVの読み込みに失敗しました',
        content: message,
        actions: ['閉じる', ''],
        color: 'warn',
      };
      this.dialog.open(ConfirmDialogComponent, {
          autoFocus: false,
          data,
      });
      return;
    }

    if (!parsedCsv) {
      return;
    }
    const errors = this.checkParsedCsv(parsedCsv);
    if ((errors.length ?? 0) > 0) {
      const message = errors?.join('\n');
      const data: ConfirmDialogData = {
        title: 'CSVの読み込みに失敗しました',
        content: message,
        actions: ['閉じる', ''],
        color: 'warn',
      };
      this.dialog.open(ConfirmDialogComponent, {
          autoFocus: false,
          data,
      });
      return;
    }

    this.resetDiagnosisValues();
    this.form.patchValue(parsedCsv.formValues);
    this.diagnosisValues = parsedCsv.diagnosisValues;

    if (this.individualAssessmentOnly()) {
      this.diagnosisValues['overallAssessment'] = this.diagnosisValues['individualAssessment'];
    } else {
      this.diagnosisValues['individualAssessment'] = this.diagnosisValues['overallAssessment'];
    }
  }

  async readFile(file: File) {
    const buf = await new Promise<ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (ev) => {
        resolve(ev.target?.result as ArrayBuffer);
      };
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.readAsArrayBuffer(file);
    });

    const utf8Decoder = new TextDecoder('utf-8', { ignoreBOM: true });
    return utf8Decoder.decode(buf);
  }

  parseCsv(text: string): { parsedCsv?: ParsedCsv, errors?: string[] } {
    const errors: string[] = [];

    const lines = text.split(/\r\n|\r|\n/);
    const rows = [];

    for (const line of lines) {
      const cols = line
        .trim()
        .split(',')
        .map(col => col.trim());
      rows.push(cols);
    }

    if (rows.length < 2) {
      errors.push('CSVの行数が2行以下');
      return { errors: errors };
    }

    const keys = rows[0];
    const values = rows[1];

    if (values.length < keys.length) {
      errors.push('項目とコードの個数の不一致')
      return { errors: errors };
    }

    const keyMap = new Map<string, string>([
      ['[Scheie(S)]', 'scheieS'],
      ['[Scheie(H)]', 'scheieH'],
      ['[Keith-Wagner]', 'keithWagner'],
      ['[Wong-Mitchell]', 'wongMitchell'],
      ['[改変Davis]', 'modifiedDavis'],
      ['[新福田]', 'newFukuda'],
      ['[Scott]', 'scott'],
      ['[その他所見]', 'findings'],
      ['[総合判定]', 'overallAssessment'],
    ]);

    const formValues: { [key: string]: string | boolean } = {};
    const diagnosisValues: { [key: string]: DiagnosisValue[] } = {};

    let currentKey = '';
    let currentValues: DiagnosisValue[] = [];

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = values[i];

      if (key.startsWith('[') && key.endsWith(']')) {
        if (this.diagnosisValues[currentKey]) {
          diagnosisValues[currentKey] = currentValues;
          if (currentKey === 'overallAssessment') {
            diagnosisValues['individualAssessment'] = currentValues;
          }
        }
        currentKey = keyMap.get(key) ?? '';
        currentValues = [];
      }

      switch (key) {
        case '[診断テンプレート名]':
          formValues['name'] = value;
          break;
        case '[デフォルト]':
          formValues['isDefaultTemplate'] = value === '1';
          break;
        case '[Scheie]':
          formValues['scheie'] = value === '1';
          break;
        case '[Scheie(S)]':
          break;
        case '[Scheie(H)]':
          break;
        case '[Keith-Wagner]':
          formValues['keithWagner'] = value === '1';
          break;
        case '[Wong-Mitchell]':
          formValues['wongMitchell'] = value === '1';
          break;
        case '[改変Davis]':
          formValues['modifiedDavis'] = value === '1';
          break;
        case '[新福田]':
          formValues['newFukuda'] = value === '1';
          break;
        case '[Scott]':
          formValues['scott'] = value === '1';
          break;
        case '[その他所見]':
          formValues['findingsText'] = value === '1';
          break;
        case '[総合判定]':
          formValues['overallAssessment'] = value === '1';
          break;
        case '[左右判定]' :
          formValues['individualAssessment'] = value === '1';
          break;
        default:
          currentValues.push({
            classification: key,
            _classification: key,
            code: value,
            _code: value,
            new: true,
          });
          break;
      }
    }

    return {
      parsedCsv: {
        formValues: formValues,
        diagnosisValues: diagnosisValues,
      },
      errors: errors,
    };
  }

  checkParsedCsv(parsedCsv: ParsedCsv): string[] {
    const errors: string[] = [];

    if (parsedCsv.formValues['scheie']) {
      errors.push(...this.checkScheieH(parsedCsv.diagnosisValues['scheieH']));
      errors.push(...this.checkScheieS(parsedCsv.diagnosisValues['scheieS']));
    }

    if (parsedCsv.formValues['keithWagner']) {
      errors.push(...this.checkKeithWagner(parsedCsv.diagnosisValues['keithWagner']));
    }

    if (parsedCsv.formValues['wongMitchell']) {
      const values = parsedCsv.diagnosisValues['wongMitchell'];
      if ((values?.length ?? 0) < 1) {
        errors.push('[Wong-Mitchell]に項目が存在しません');
      }
      if (!this.checkDuplicateClassification(values)) {
        errors.push('[Wong-Mitchell]の分類が重複しています')
      }
      if (!this.checkDuplicateCode(values)) {
        errors.push('[Wong-Mitchell]のコードが重複しています')
      }
      errors.push(...this.checkEmpty('[Wong-Mitchell]', values));
    }

    if (parsedCsv.formValues['modifiedDavis']) {
      const values = parsedCsv.diagnosisValues['modifiedDavis'];
      if ((values?.length ?? 0) < 1) {
        errors.push('[改変Davis]に項目が存在しません');
      }
      if (!this.checkDuplicateClassification(values)) {
        errors.push('[改変Davis]の分類が重複しています')
      }
      if (!this.checkDuplicateCode(values)) {
        errors.push('[改変Davis]のコードが重複しています')
      }
      errors.push(...this.checkEmpty('[改変Davis]', values));
    }

    if (parsedCsv.formValues['newFukuda']) {
      const values = parsedCsv.diagnosisValues['newFukuda'];
      if ((values?.length ?? 0) < 1) {
        errors.push('[新福田]に項目が存在しません');
      }
      if (!this.checkDuplicateClassification(values)) {
        errors.push('[新福田]の分類が重複しています')
      }
      if (!this.checkDuplicateCode(values)) {
        errors.push('[新福田]のコードが重複しています')
      }
      errors.push(...this.checkEmpty('[新福田]', values));
    }

    if (parsedCsv.formValues['scott']) {
      const values = parsedCsv.diagnosisValues['scott'];
      if ((values?.length ?? 0) < 1) {
        errors.push('[Scott]に項目が存在しません');
      }
      if (!this.checkDuplicateClassification(values)) {
        errors.push('[Scott]の分類が重複しています')
      }
      if (!this.checkDuplicateCode(values)) {
        errors.push('[Scott]のコードが重複しています')
      }
      errors.push(...this.checkEmpty('[Scott]', values));
    }

    if (parsedCsv.formValues['findingsText']) {
      const values = parsedCsv.diagnosisValues['findings'];
      if ((values?.length ?? 0) < 1) {
        errors.push('[その他所見]に項目が存在しません');
      }
      if (!this.checkDuplicateClassification(values)) {
        errors.push('[その他所見]の分類が重複しています')
      }
      if (!this.checkDuplicateCode(values)) {
        errors.push('[その他所見]のコードが重複しています');
      }
      errors.push(...this.checkEmpty('[その他所見]', values));
    }

    if (parsedCsv.formValues['overallAssessment']) {
      const values = parsedCsv.diagnosisValues['overallAssessment'];
      if ((values?.length ?? 0) < 1) {
        errors.push('[総合判定]に項目が存在しません');
      }
      if (!this.checkDuplicateClassification(values)) {
        errors.push('[総合判定]の分類が重複しています')
      }
      if (!this.checkDuplicateCode(values)) {
        errors.push('[総合判定]のコードが重複しています')
      }
      errors.push(...this.checkEmpty('[総合判定]', values));
    }

    return errors;
  }

  checkScheieH(values: DiagnosisValue[]): string[] {
    const errors: string[] = [];
    for (const c of scheieH) {
      if (!values.some(v => v.classification === c)) {
        errors.push(`[Scheie(H)]の分類:${c}が入力されていません`);
      }
    }
    for (const v of values) {
      if (v.code === '') {
        errors.push(`[Scheie(H)]の分類:${v.classification}が入力されていません`);
      }
    }
    if (!this.checkDuplicateClassification(values)) {
      errors.push('[Sheie(H)]の分類が重複しています')
    }
    if (!this.checkDuplicateCode(values)) {
      errors.push('[Scheie(H)]のコードが重複しています');
    }
    return errors;
  }

  checkScheieS(values: DiagnosisValue[]): string[] {
    const errors: string[] = [];
    for (const c of scheieS) {
      if (!values.some(v => v.classification === c)) {
        errors.push(`[Scheie(S)]の分類:${c}が入力されていません`);
      }
    }
    for (const v of values) {
      if (v.code === '') {
        errors.push(`[Scheie(S)]の分類:${v.classification}が入力されていません`);
      }
    }
    if (!this.checkDuplicateClassification(values)) {
      errors.push('[Sheie(S)]の分類が重複しています')
    }
    if (!this.checkDuplicateCode(values)) {
      errors.push('[Scheie(S)]のコードが重複しています');
    }
    return errors;
  }

  checkKeithWagner(values: DiagnosisValue[]): string[] {
    const errors: string[] = [];
    for (const c of keithWagner) {
      if (!values.some(v => v.classification === c)) {
        errors.push(`[Keith-Wagner]の分類:${c}が入力されていません`);
      }
    }
    for (const v of values) {
      if (v.code === '') {
        errors.push(`[Keith-Wagner]の分類:${v.classification}が入力されていません`);
      }
    }
    if (!this.checkDuplicateClassification(values)) {
      errors.push('[Keith-Wagner]の分類が重複しています')
    }
    if (!this.checkDuplicateCode(values)) {
      errors.push('[Keith-Wagner]のコードが重複しています');
    }
    return errors;
  }

  checkEmpty(key: string, values: DiagnosisValue[]): string[] {
    const errors: string[] = [];
    for (const v of values) {
      if (v.code === '') {
        errors.push(`${key}の分類:${v.classification}が入力されていません`);
      }
    }
    return errors;
  }

  checkDuplicateCode(values: DiagnosisValue[]): boolean {
    for (let index = 0; index < values.length; index++) {
      if (values.some((v, i) => values[index]?.code === v?.code && i !== index)) {
        return false;
      }
    }
    return true;
  }

  checkDuplicateClassification(values: DiagnosisValue[]): boolean {
    for (let index = 0; index < values.length; index++) {
      if (values.some((v, i) => values[index]?.classification === v?.classification && i !== index)) {
        return false;
      }
    }
    return true;
  }

  protected readonly Object = Object;
}
