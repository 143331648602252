<div fxLayout="column" fxLayoutGap="20px">
  <form class="card" fxLayout="column" fxLayoutGap="20px" (ngSubmit)="saveTemplate()" [formGroup]="form">
    <div fxLayout="row" fxLayoutAlign="center">
      <div fxFlex="50" fxLayoutAlign="start center">
        <span class="title">基本設定</span>
      </div>
      <div fxFlex="50" fxLayoutAlign="end start">
        <button mat-button
          class="secondly-button"
          type="button"
          (click)="onExportCsv()"
        >
          <span>CSV出力</span>
        </button>
        <button mat-button
          class="secondly-button"
          type="button"
          (click)="importEl.click()"
        >
          <span>CSV読み込み</span>
          <input class="form-control"
            type="file"
            accept=".csv"
            hidden
            #importEl
            (change)="onImportCsv($event)"
          />
        </button>
        <app-custom-tooltip
          *ngIf="userRole === 'Admin'"
          [tooltipMessage]="!this.isDeleteAllowed() ? '検査に設定されているため、削除不可' : ''"
          [style]="{display: 'inline-block'}"
        >
          <button mat-button
            class="secondly-button"
            type="button"
            [disabled]="!isDeleteAllowed()"
            (click)="deleteTemplate()"
          >
            <span>テンプレート削除</span>
          </button>
        </app-custom-tooltip>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="8px">
      <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="20px">
        <label class="template-name">テンプレート名 *</label>
        <app-input *ngIf="!isReadOnly"
          width="100%"
          height="40px"
          placeholder="---"
          fxFlex
          [formControl]="name"
        ></app-input>
        <div *ngIf="isReadOnly">{{name.value}}</div>
      </div>
      <span class="error-message" *ngIf="!name.value && name.dirty">
        必須項目が入力されていません
      </span>
      <span class="error-message" *ngIf="nameAlreadyExists && name.dirty">
        このテンプレート名はすでに使用しています
      </span>
    </div>

    <div fxLayout="row" fxLayoutGap="8px" *ngIf="!_template?.isDefaultTemplate">
      <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px" fxFlex="60%">
        <app-checkbox
          [readonly]="isReadOnly"
          formControlName="isDefaultTemplate"
          label="このテンプレートをデフォルトにする">
        </app-checkbox>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div fxLayout="column" fxLayoutGap="8px">
      <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="8px">
        <span class="title">必須診断項目</span>
        <mat-icon
          class="material-icons-outlined"
          matTooltip="診断画面の診断項目に表示"
          matTooltipPosition="above"
          >info</mat-icon
        >
      </div>

      <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px">
        <div fxLayout="column" fxLayoutGap="8px" fxFlex>
          <app-checkbox [readonly]="isReadOnly" formControlName="scheie" label="Scheie"></app-checkbox>
          <app-checkbox [readonly]="isReadOnly" formControlName="newFukuda" label="新福田"></app-checkbox>
        </div>
        <div fxLayout="column" fxLayoutGap="8px" fxFlex>
          <app-checkbox [readonly]="isReadOnly" formControlName="keithWagner" label="Keith-Wagner"></app-checkbox>
          <app-checkbox [readonly]="isReadOnly" formControlName="scott" label="Scott"></app-checkbox>
        </div>
        <div fxLayout="column" fxLayoutGap="8px" fxFlex>
          <app-checkbox [readonly]="isReadOnly" formControlName="wongMitchell" label="Wong-Mitchell"></app-checkbox>
          <app-checkbox [readonly]="isReadOnly" formControlName="findingsText" label="その他所見"></app-checkbox>
        </div>
        <div fxLayout="column" fxLayoutGap="8px" fxFlex>
          <app-checkbox [readonly]="isReadOnly" formControlName="modifiedDavis" label="改変Davis"></app-checkbox>
          <app-checkbox [readonly]="isReadOnly" formControlName="overallAssessment" label="総合判定"></app-checkbox>
        </div>
        <div fxLayout="column" fxLayoutGap="8px" fxFlex>
          <app-checkbox [readonly]="isReadOnly" formControlName="individualAssessment" label="左右判定"></app-checkbox>
        </div>
      </div>
    </div>

    <mat-divider *ngIf="hasCheckedOne"></mat-divider>

    <div fxLayout="column" fxLayoutGap="8px" *ngIf="hasCheckedOne">
      <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="8px">
        <span class="title">分類選択</span>
        <mat-icon
          class="material-icons-outlined"
          matTooltip="診断項目の選択肢と紐づくコードを設定"
          matTooltipPosition="above"
        >info</mat-icon
        >
      </div>
      <div fxLayout="row" class="classes">
        <div fxLayout="column" fxFlex="0 0 auto" class="nav-items">
          <div *ngFor="let diagnosisItem of defaultClassifications">
            <div fxLayout="column"
                 *ngIf="showDiagnosisItem(diagnosisItem.field)"
                 class="nav-item"
                 [ngClass]="{
                    'selected': currentSelectedDiagnosisItem === diagnosisItem.field,
                    'error': currentSelectedDiagnosisItem !== diagnosisItem.field && diagnosisItemsWithErrors.includes(diagnosisItem.field)
                 }"
                 (click)="selectedDiagnosisItem = diagnosisItem.field"
            >
              {{diagnosisItem.name}}
            </div>
          </div>
        </div>
        <div fxFlex="1 1 auto" class="diagnosis-items">
          <div  *ngFor="let diagnosisItem of defaultClassifications" >
            <div *ngIf="currentSelectedDiagnosisItem === diagnosisItem.field">
                <div class="diagnosis-items-head">
                  <table>
                    <thead>
                    <tr>
                      <th
                          [style.width.px]="!diagnosisItem.canAdd ? 112 : (isReadOnly ? 256 : 224)"
                          *ngIf="!hasCategoryAsHeader(diagnosisItem.field)"
                        >
                        所見
                      </th>
                      <th
                        [style.width.px]="!diagnosisItem.canAdd ? 112 : (isReadOnly ? 256 : 224)"
                        *ngIf="hasCategoryAsHeader(diagnosisItem.field)"
                        >
                        分類
                      </th>
                      <th>
                        コード
                      </th>
                      <th *ngIf="diagnosisItem.canAdd" [style.width.px]="55"></th>
                    </tr>
                    </thead>
                  </table>
                </div>
                <div class="diagnosis-items-contents">
                  <table  style="width: 95%;" >
                    <tbody>
                    <tr *ngFor="let classification of diagnosisValues[diagnosisItem.field]; let i = index;">
                      <td #tableCol1 style="min-width: 110px;" [style.padding-left]="!diagnosisItem.canAdd ? '8.5' : '4.5'">
                        <span *ngIf="!diagnosisItem.canAdd">{{classification.classification}}</span>
                        <input
                          *ngIf="diagnosisItem.canAdd"
                          type="text"
                          [readonly]="isReadOnly"
                          [value]="classification.classification"
                          (keyup)="changeDiagnosisValues($event, diagnosisItem.field, i, 'classification')"
                          [style.width]="'95%'"
                          placeholder="---"
                          [ngClass]="diagnosisValueErrors[diagnosisItem.field +':'+ i + ':'+'classification'] ? 'error' : ''"
                        />
                        <span class="error-message" *ngIf="diagnosisValueErrors[diagnosisItem.field +':'+ i + ':'+'classification'] === 'isEmpty'" >
                        必須項目が入力されていません
                      </span>
                        <span class="error-message" *ngIf="diagnosisValueErrors[diagnosisItem.field +':'+ i + ':'+'classification'] === 'isDuplicate'" >
                        この値は既に存在します
                      </span>
                      </td>
                      <td #tableCol2>
                        <input
                          type="text"
                          [readonly]="isReadOnly"
                          (keyup)="changeDiagnosisValues($event, diagnosisItem.field, i, 'code')"
                          [value]="classification.code"
                          [style.width]="!diagnosisItem.canAdd ? '400px' : '95%'"
                          placeholder="---"
                          [ngClass]="diagnosisValueErrors[diagnosisItem.field +':'+ i + ':'+'code'] ? 'error' : ''"
                        />
                        <span class="error-message" *ngIf="diagnosisValueErrors[diagnosisItem.field +':'+ i + ':'+'code'] === 'isEmpty'" >
                        必須項目が入力されていません
                      </span>
                        <span class="error-message" *ngIf="diagnosisValueErrors[diagnosisItem.field +':'+ i + ':'+'code'] === 'isDuplicate'" >
                        この値は既に存在します
                      </span>
                      </td>
                      <td *ngIf="diagnosisItem.canAdd && !isReadOnly" [style.width.px]="55">
                        <div fxLayout="row" [style.width.px]="50">
                          <button (click)="addDiagnosisValue(diagnosisItem.field, i)" mat-icon-button>
                            <mat-icon>add</mat-icon>
                          </button>
                          <button [disabled]="diagnosisValues[diagnosisItem.field].length <= 1" (click)="removeDiagnosisValue(diagnosisItem.field, i)" mat-icon-button>
                            <mat-icon class="material-icons-outlined">delete</mat-icon>
                          </button>
                        </div>

                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>

              </div>

            </div>
        </div>

      </div>
    </div>

    <div fxLayout="column" fxLayoutGap="12px" *ngIf="!isReadOnly">
      <mat-divider></mat-divider>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
        <app-button [disabled]="!hasChanges()" type="button" width="70px" height="25px" (click)="handleCancel()"
          >キャンセル</app-button
        >
        <app-button type="submit" color="primary" width="52px" height="25px" [disabled]="!isSaveAllowed()"
          >保存</app-button
        >
      </div>
    </div>
  </form>
</div>
