import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  Invitation,
  Member,
  Organization,
  OrganizationCreateDto,
  OrganizationUpdateDto,
  Role,
  User,
} from '@app/core/models';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class Auth0ApiService {
  apiPath = `${environment.auth0Url}/api/v2`;

  constructor(private http: HttpClient) {}

  /**
   * ユーザーを取得する
   * @param id ユーザーID
   * @returns ユーザー
   */
  getUser(id: string) {
    return this.http.get<User>(`${this.apiPath}/users/${id}`);
  }

  /**
   * ユーザーを更新する
   * @param id ユーザーID
   * @param user ユーザー
   * @returns ユーザー
   */
  updateUser(id: string, user: User) {
    return this.http.patch<User>(`${this.apiPath}/users/${id}`, user);
  }

  /**
   * 施設を取得する
   * @param id 施設ID
   * @returns 施設
   */
  getOrganization(id: string) {
    return this.http.get<Organization>(`${this.apiPath}/organizations/${id}`);
  }

  /**
   * 施設一覧を取得する
   * @returns 施設一覧
   */
  getOrganizations() {
    return this.http.get<Organization[]>(`${this.apiPath}/organizations`);
  }

  /**
   * 所属施設を取得する
   * @param id 施設ID
   * @returns 施設
   */
  getBelongedOrganizations(id: string) {
    return this.http.get<Organization[]>(
      `${this.apiPath}/users/${id}/organizations`
    );
  }

  /**
   * 施設を作成する
   * @param organizationCreateDto 施設
   * @returns 施設
   */
  createOrganization(organizationCreateDto: OrganizationCreateDto) {
    return this.http.post<Organization>(`${this.apiPath}/organizations`, {
      ...organizationCreateDto,
      enabled_connections: [
        {
          connection_id: environment.connection_id,
        },
      ],
    });
  }

  /**
   * 施設を更新する
   * @param id 施設ID
   * @param organizationUpdateDto 施設
   * @returns 施設
   */
  updateOrganization(id: string, organizationUpdateDto: OrganizationUpdateDto) {
    return this.http.patch<Organization>(
      `${this.apiPath}/organizations/${id}`,
      {
        ...organizationUpdateDto,
      }
    );
  }

  /**
   * メンバー一覧を取得する
   * @param id 施設ID
   * @returns メンバー
   */
  getMembers(id: string) {
    return this.http.get<(Member & { roles: Role[] })[]>(
      `${this.apiPath}/organizations/${id}/members?fields=email,name,picture,user_id,roles`
    );
  }

  /**
   * メンバーのロールを取得する
   * @param organizationId 施設ID
   * @param userId ユーザーID
   * @returns ロール
   */
  getRolesOfMember(organizationId: string, userId: string) {
    return this.http.get<Role[]>(
      `${this.apiPath}/organizations/${organizationId}/members/${userId}/roles`
    );
  }

  /**
   * メンバーのロールを追加する
   * @param organizationId 施設ID
   * @param userId ユーザーID
   * @param roles ロール
   * @returns ロール
   */
  addRolesOfMember(organizationId: string, userId: string, roles: string[]) {
    return this.http.post<Role[]>(
      `${this.apiPath}/organizations/${organizationId}/members/${userId}/roles`,
      { roles }
    );
  }

  /**
   * メンバーのロールを取得する
   * @param organizationId 施設ID
   * @param userId ユーザーID
   * @param roles ロール
   * @returns ロール
   */
  deleteRolesOfMember(organizationId: string, userId: string, roles: string[]) {
    return this.http.delete<Role[]>(
      `${this.apiPath}/organizations/${organizationId}/members/${userId}/roles`,
      { body: { roles } }
    );
  }

  /**
   * メンバーを追加する
   * @param id 施設ID
   * @param members メンバー
   */
  addMembers(id: string, members: string[]) {
    return this.http.post<void>(`${this.apiPath}/organizations/${id}/members`, {
      members,
    });
  }

  /**
   * メンバーを削除する
   * @param organizationId 施設ID
   * @param userId ユーザーID
   */
  deleteMember(organizationId: string, userId: string) {
    return this.http.delete<void>(
      `${this.apiPath}/organizations/${organizationId}/members`,
      { body: { members: [userId] } }
    );
  }

  /**
   * 招待一覧を取得する
   * @param id 施設ID
   * @returns 招待
   */
  getInvitation(id: string) {
    return this.http.get<Invitation[]>(
      `${this.apiPath}/organizations/${id}/invitations`
    );
  }

  /**
   * メンバーを招待する
   * @param id 施設ID
   * @param member メンバー
   */
  createInvitation(
    id: string,
    member: { email: string; role: string },
    name: string
  ) {
    return this.http.post<void>(
      `${this.apiPath}/organizations/${id}/invitations`,
      {
        inviter: { name },
        invitee: { email: member.email },
        client_id: environment.clientId,
        roles: [member.role],
      }
    );
  }

  /**
   * 招待を削除する
   * @param organizationId 施設ID
   * @param invitationId 招待ID
   */
  deleteInvitation(organizationId: string, invitationId: string) {
    return this.http.delete<void>(
      `${this.apiPath}/organizations/${organizationId}/invitations/${invitationId}`
    );
  }

  /**
   * ロールを取得する
   * @returns ロール
   */
  getRoles() {
    return this.http.get<Role[]>(`${this.apiPath}/roles`);
  }
}
