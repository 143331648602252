import { Injectable } from '@angular/core';
import { AuthService, User as UserInfo } from '@auth0/auth0-angular';
import { firstValueFrom, Observable } from 'rxjs';

import { Auth0ApiService } from '@app/core/services';
import {
  Member,
  OrganizationCreateDto,
  OrganizationUpdateDto,
  Role,
  User,
} from '@app/core/models';
import { environment } from '@environments/environment';
import { DownloadEncoding, DownloadType, FileGrouping } from '@app/core/models/report.model';

export interface UserSettings {
  shortcutKeys?: any;
  showHeader?: boolean;
  downloadType?: DownloadType;
  downloadEncoding?: DownloadEncoding;
  fileGrouping?: FileGrouping;
  patientPrioritySource?: 'dicom' | 'csv';
  googleName?: string;
  defaultPageSize?: number;
}

@Injectable({
  providedIn: 'root',
})
export class Auth0Service {
  get isAuth() {
    return firstValueFrom(this.auth.isAuthenticated$);
  }

  constructor(
    private auth: AuthService,
    private auth0ApiService: Auth0ApiService,
  ) {}

  /**
   * 招待を受け入れる
   */
  async acceptInvitation() {
    localStorage.removeItem('fundusApiCache');

    const url = new URL(location.href);
    let invitation =
      url.searchParams.get('invitation') ||
      sessionStorage.getItem('invitation') ||
      '';
    let organization =
      url.searchParams.get('organization') ||
      sessionStorage.getItem('organization') ||
      '';
    sessionStorage.removeItem('invitation');
    sessionStorage.removeItem('organization');

    // ログインしていない場合
    if (!(await this.isAuth)) {
      sessionStorage.setItem('invitation', invitation);
      sessionStorage.setItem('organization', organization);
      return;
    }

    this.changeSelectedOrganization({ organization });
    this.auth.loginWithRedirect({ invitation, organization }).subscribe();
  }

  /**
   * ログアウトする
   */
  logout() {
    localStorage.removeItem('fundusApiCache');
    sessionStorage.removeItem('selectedFundusStudies');

    try {
      this.auth.logout({ returnTo: `${window.location.origin}/login.html` });
    } catch (e) {
      alert('ログアウトに失敗しました');
    }
  }

  /**
   * 自分のユーザー情報を取得する
   */
  getOwnUserInfo() {
    return firstValueFrom(this.auth.user$ as Observable<UserInfo>);
  }

  /**
   * ユーザーを取得する
   */
  async getUser(id: string) {
    return firstValueFrom(this.auth0ApiService.getUser(id));
  }

  /**
   * ユーザーを更新する
   */
  async updateUser(user: User) {
    const me = await this.getOwnUserInfo();
    return firstValueFrom(this.auth0ApiService.updateUser(me.sub || '', user));
  }

  /**
   * 選択している施設を変更する
   */
  async changeSelectedOrganization(options: { organization: string }) {
    await this.updateUser({
      user_metadata: { selectedOrganization: options.organization },
    });
    return await firstValueFrom(
      this.auth.getAccessTokenSilently({
        ...options,
        ignoreCache: true,
        audience: environment.audience,
      })
    );
  }

  /**
   * 施設IDを取得する
   */
  async getOrganizationId() {
    return (await this.getOwnUserInfo())['org_id'];
  }

  /**
   * 施設を取得する
   */
  async getOrganization() {
    const id = await this.getOrganizationId();
    return firstValueFrom(this.auth0ApiService.getOrganization(id));
  }

  /**
   * 施設一覧を取得する
   */
  getOrganizations() {
    return firstValueFrom(this.auth0ApiService.getOrganizations());
  }

  /**
   * 所属施設を取得する
   */
  async getBelongedOrganizations() {
    const me = await this.getOwnUserInfo();
    return firstValueFrom(
      this.auth0ApiService.getBelongedOrganizations(me.sub || '')
    );
  }

  /**
   * 施設を作成する
   */
  async createOrganization(organizationCreateDto: OrganizationCreateDto) {
    const me = await this.getOwnUserInfo();

    // 組織を作成
    const organization = await firstValueFrom(
      this.auth0ApiService.createOrganization(organizationCreateDto)
    );
    // 作成した組織に自分を追加
    await firstValueFrom(
      this.auth0ApiService.addMembers(organization.id, [me.sub || ''])
    );
    // 管理者のロールIDを取得
    const roles = await this.getRoles();
    const admin = roles.find((role) => role.name === 'Admin') as Role;
    // 自分を管理者として設定
    await firstValueFrom(
      this.auth0ApiService.addRolesOfMember(organization.id, me.sub || '', [
        admin.id,
      ])
    );
    // 作成した組織を選択
    this.changeSelectedOrganization({ organization: organization.id });

    return organization;
  }

  /**
   * 施設を更新する
   */
  async updateOrganization(organizationUpdateDto: OrganizationUpdateDto) {
    const id = await this.getOrganizationId();
    return await firstValueFrom(
      this.auth0ApiService.updateOrganization(id, organizationUpdateDto)
    );
  }

  /**
   * メンバー一覧を取得する
   */
  async getMembersWithRole() {
    const _members: (Member & { role: Role })[] = [];

    const organizationId = await this.getOrganizationId();
    const members = await firstValueFrom(
      this.auth0ApiService.getMembers(organizationId)
    );
    for (const member of members) {
      _members.push({ ...member, role: member.roles[0] });
    }

    return _members;
  }

  /**
   * メンバーを追加する
   */
  async addRolesOfMember(userId: string, roles: string[]) {
    const organizationId = await this.getOrganizationId();
    return firstValueFrom(
      this.auth0ApiService.addRolesOfMember(organizationId, userId, roles)
    );
  }

  /**
   * メンバーを削除する
   */
  async deleteRolesOfMember(userId: string, roles: string[]) {
    const organizationId = await this.getOrganizationId();
    return firstValueFrom(
      this.auth0ApiService.deleteRolesOfMember(organizationId, userId, roles)
    );
  }

  /**
   * メンバーを追加する
   */
  addMembers(organization: string, members: string[]) {
    return firstValueFrom(
      this.auth0ApiService.addMembers(organization, members)
    );
  }

  /**
   * メンバーを削除する
   */
  async deleteMember(userId: string) {
    const organizationId = await this.getOrganizationId();
    return firstValueFrom(
      this.auth0ApiService.deleteMember(organizationId, userId)
    );
  }

  /**
   * 招待一覧を取得する
   */
  async getInvitations() {
    const organizationId = await this.getOrganizationId();
    return firstValueFrom(this.auth0ApiService.getInvitation(organizationId));
  }

  /**
   * メンバーを招待する
   */
  async createInvitation(
    organization: string,
    member: { email: string; role: string }
  ) {
    const own = await this.getOwnUserInfo();
    return firstValueFrom(
      this.auth0ApiService.createInvitation(
        organization,
        member,
        own.name || ''
      )
    );
  }

  /**
   * メンバーを招待する
   */
  async createInvitations(
    organization: string,
    members: { email: string; role: string }[]
  ) {
    for (const member of members) {
      await this.createInvitation(organization, member);
    }
  }

  /**
   * 招待を削除する
   */
  async deleteInvitation(invitationId: string) {
    const organizationId = await this.getOrganizationId();
    return firstValueFrom(
      this.auth0ApiService.deleteInvitation(organizationId, invitationId)
    );
  }

  /**
   * ロールを取得する
   */
  getRoles() {
    return firstValueFrom(this.auth0ApiService.getRoles());
  }

  /**
   * ユーザー設定を取得
   */
  async getUserSettings() : Promise<UserSettings> {
    let ownUser = await this.getOwnUserInfo();
    if(ownUser.sub) {
      let user = await this.getUser(ownUser.sub);
      return user.user_metadata;
    }

    return {};

  }

  /**
   * ユーザー設定保存
   */
  async saveUserSettings(meta: UserSettings) {
    await this.updateUser({
      user_metadata: meta
    });
  }
}
